
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/doctors/d/[doctorSlug]",
      function () {
        return require("private-next-pages/doctors/d/[doctorSlug]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/doctors/d/[doctorSlug]"])
      });
    }
  